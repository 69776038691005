import React, { useEffect } from 'react'
import { Padding } from '@papertrail/styleguide'
import { Tracking, useTitle } from '@papertrail/web3-utils'
import { useLoginViaSSOApi } from './hooks'
import { useHistory } from 'react-router-dom'

type Props = {
  locationSearch: string
}

export default function OAuthRedirect(props: Props) {
  const history = useHistory()

  const [loginState, doLogin] = useLoginViaSSOApi()

  useTitle('OAuth Redirect')

  const { locationSearch } = props

  const params = new URLSearchParams(locationSearch)
  const code = params.get('code')
  const state = params.get('state')

  // If the state is set, there is an invitation that needs accepting on SSO login
  let invitation = localStorage.getItem(`invitation-${state}`)

  if (invitation) {
    invitation = JSON.parse(invitation)
  }

  useEffect(() => {
    if (code) {
      doLogin(code, invitation ? invitation['invitation_id'] : null)
    }
  }, [code])

  useEffect(() => {
    // If there is an error with authentication, redirect to the login page
    if (loginState.isError) {
      history.push('/login')
    }

    if (loginState.isLoaded) {
      Tracking.trackEvent('loginSSO')
      Tracking.trackEvent('login')
      localStorage.removeItem(`invitation-${state}`)
      sessionStorage.clear()
      history.push('/')
    }
  })

  function renderContent() {
    if (!code) {
      return <Padding>code parameter missing.</Padding>
    }

    return <Padding>Logging in, please wait...</Padding>
  }

  return renderContent()
}
